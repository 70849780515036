import { Link } from 'gatsby';
import React from 'react';
import Layout from 'components/layout';
import SEO from 'components/seo';
import Button from 'components/button';

const Benefit = ({ text }) => (
  <div className="flex items-center gap-x-2 font-medium text-primary mb-4">
    <div className="w-6 h-6">
      <img alt="check" src="/images/check_circle.svg" className="w-full" />
    </div>
    <div>
      <p>{text}</p>
    </div>
  </div>
);

const Analysis = () => (
  <Layout>
    <SEO
      title="Spend Analysis"
      description="A Maistro Tail Spend analysis uses PowerBI to highlight risks and opportunities across your indirect supply base"
    />
    <div>
      <div className="grid grid-rows-2 md:grid-cols-2 md:grid-rows-none">
        <div className="bg-primary flex justify-center items-center py-6 lg:py-10">
          <div className="w-10/12">
            <h1 className="text-white normal-case font-medium">
              Focus your tail spend with powerful analytics and data
              visualisation.
            </h1>
            <h4 className="text-white normal-case text-2xl my-9">
              Real-time visibility across all categories and actionable insights
              helps you identify new opportunities and hidden savings.
            </h4>
            <Link to="#benefits">
              <Button
                text="Learn from your Data"
                bg="primary"
                rightIcon="/images/chevronRightIcon.svg"
              />
            </Link>
          </div>
        </div>
        <div
          style={{
            backgroundImage: 'url(/images/Analysis_A.png)',
          }}
          className="bg-cover bg-center"
        />
      </div>
    </div>
    <div className="bg-white py-12">
      <div className="container">
        <div className="flex items-center justify-between isMxl:justify-center isMxl:flex-col">
          <div className="isMxl:mb-12 w-image isMxl:w-auto pl-4 pr-4">
            <img
              src="/images/Analysis_B.png"
              alt="Data Dashboard"
              className="w-full"
            />
          </div>
          <div className="w-11/12 isMxl:w-full pl-4 isMxl:pl-0 text-left text-primary">
            <h1 className="font-bold mb-8">
              50% of tail transactions take place without any procurement
              oversight or governance
            </h1>
            <p className="font-medium mb-6">
              We understand - not every purchase needs procurement’s
              involvement. By its very nature, low-value/indirect spend relies
              on users throughout the organisation being able to purchase
              services quickly and easily which is why 80% of the supply base
              exists within the tail.
            </p>
            <p className=" font-medium">
              However, the flip-side to this type of frictionless purchasing
              will be a lack of visibility and control and left unchecked, can
              lead to situations where risk is increased without red-flags being
              raised in time for the right people to mitigate.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div id="benefits" className="py-12">
      <div className="container">
        <div className="flex items-center justify-between isMd:justify-center isMd:flex-col gap-x-4">
          <div className="w-full isMd:mb-12">
            <div className="text-left mb-8">
              <h1 className="text-primary">Bring your data to life</h1>
            </div>
            <div className="mb-10">
              <p className="text-primary">
                The ability to capitalise on data insights and analytics can
                make or break the competitive advantage of a company. Our
                ‘Insight Builder’ Technology transforms raw procurement spend
                and contract data into a business analytics visibility tool,
                with easy-to-understand dashboards and analysis, in the form of
                Microsoft PowerBi graphs, charts, tables and text.
              </p>
            </div>
          </div>
          <div>
            <img
              src="/images/Analysis_C.png"
              alt="Mobile phone showing data"
              className="w-full max-w-5xl pw-8"
            />
          </div>
        </div>
      </div>
    </div>

    <div className="bg-secondary py-12">
      <div className="container">
        <div className="grid isMd:grid-cols-1 grid-cols-2 items-center gap-6 p-6">
          <div className="isMd:mb-12">
            <h1 className="text-primary normal-case font-semibold">
              What are the benefits of a spend analysis?
            </h1>
            <p className="text-primary normal-case text-2xl my-12">
              A Maistro spend analysis will not only highlight areas that can be
              targeted for cost-savings but will also provide you with real
              actionable insight. We present your data to you in a way that
              offers solutions on how to best use your newly acquired visibility
              to plan your next steps.
            </p>
            <a href="mailto:enquiries@maistro.com?subject=Get Data Insights">
              <Button
                text="Get Data Insights"
                bg="primary"
                rightIcon="/images/chevronRightIcon.svg"
              />
            </a>
          </div>
          <div className="pl-32 isMd:pl-0">
            <Benefit text="Who purchases what and from where" />
            <Benefit text="Evaluate supplier performance" />
            <Benefit text="Categorise supplier verticals" />
            <Benefit text="Align company values" />
            <Benefit text="Gain visibility on spend behaviours" />
            <Benefit text="Benchmark internal performance" />
            <Benefit text="Procurement calender" />
            <Benefit text="Horizon planning" />
          </div>
        </div>
      </div>
    </div>

    <div className="bg-stone py-12">
      <div className="container">
        <div className="pb-14 text-center text-primary">
          <h1>How does it work?</h1>
        </div>
        <div className="flex items-center justify-between isMd:justify-center isMd:flex-col">
          <div className="text-center w-80 mb-12">
            <div className="w-36 h-30 my-0 mx-auto mb-12">
              <img
                src="/images/step_1.png"
                alt="step_1"
                className="w-full h-full"
              />
            </div>
            <div className="mb-4">
              <h3 className="text-primary normal-case font-semibold">Step 1</h3>
            </div>
            <p className="text-primary font-normal">
              The discovery phase uncovers what data we recommend from you to
              begin the analysis
            </p>
          </div>
          <div className="text-center w-80 mb-12">
            <div className="w-36 h-30 my-0 mx-auto mb-16 mt-4">
              <img
                src="/images/step_2.png"
                alt="step_2"
                className="w-full h-full"
              />
            </div>
            <div className="mb-4">
              <h3 className="text-primary normal-case font-semibold">Step 2</h3>
            </div>
            <p className="text-primary font-normal">
              We work through your data, sorting, categorising and identifying
              patterns, and opportunities for savings
            </p>
          </div>
          <div className="text-center w-80 mb-12">
            <div className="w-36 h-30 my-0 mx-auto mb-12">
              <img
                src="/images/step_3.png"
                alt="step_3"
                className="w-full h-full"
              />
            </div>
            <div className="mb-4">
              <h3 className="text-primary normal-case font-semibold">Step 3</h3>
            </div>
            <p className="text-primary font-normal">
              We play back our findings in an easy to understand format that
              highlights opportunities and risks
            </p>
          </div>
        </div>
        <div className="text-center py-4">
          <a href="mailto:enquiries@maistro.com?subject=Get started">
            <Button
              text="Get Started"
              rightIcon="/images/chevronRightIcon.svg"
              bg="primary"
            />
          </a>
        </div>
      </div>
    </div>
  </Layout>
);

export default Analysis;
